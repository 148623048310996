import api from './api';

const url = 'service-unity';

const create = (unit) => api.post(`${url}/create-service-unity`, unit);

const findAll = () => api.get(`${url}/read-service-unity`);

const update = (unit) => api.put(`${url}/update-service-unity`, unit);

const deleteOne = (id) => api.delete(`${url}/delete-service-unity/${id}`);

export default {
  create, findAll, update, deleteOne,
};
