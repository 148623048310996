<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="units"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Unidades de Atendimento</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.phone="{ item }">
        <p>{{ item.phone | phoneFormatted }}</p>
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-units
        v-if="dialog"
        :populate-with="unitsToEdit"
        @unit-added="addUnit"
        @unit-edited="editUnit"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import UnitService from '../../../services/unit.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'Units',
  components: {
    'form-units': () => import('./Form'),
    // DeleteConfirmation,
  },

  data: () => ({
    headers: [
      {
        text: 'Título',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Endereço',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Telefone',
        sortable: false,
        value: 'primaryPhone',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    units: [],
    search: '',
    dialog: false,
    unitsToEdit: {},
    dialogDeleteConfirmation: false,
  }),

  async created() {
    try {
      this.units = await UnitService.findAll();
    } catch (e) {
      this.$handleHttpError(e);
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.unitsToEdit = {};
    },

    editItem(item) {
      this.unitsToEdit = item;
      this.dialog = true;
    },

    // prepareToDelete(item) {
    //   this.dialogDeleteConfirmation = true;
    //   this.deleteItemID = item.id;
    // },

    // async deleteItem() {
    //   try {
    //     await UnitService.deleteOne(this.deleteItemID);
    //     const index = this.units.findIndex((listUnits) => listUnits.id === this.deleteItemID);
    //     this.units.splice(index, 1);
    //     this.dialogDeleteConfirmation = false;
    //     this.$toast.success('Unidade de Atendimento excluída com sucesso');
    //   } catch (e) {
    //     this.$handleHttpError(e);
    //   }
    // },

    addUnit(units) {
      this.units.push(units);
    },

    editUnit(units) {
      const index = this.units.findIndex((listUnits) => listUnits.id === units.id);
      this.units[index] = units;
    },
  },
};
</script>
